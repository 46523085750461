export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const METHOD_SIGNUP = 'SIGNUP';
export const METHOD_SIGNIN = 'SIGNIN';
export const METHOD_FORGET_PASS = 'FORGET_PASS';

export const GET_INFO_START = "GET_INFO_START";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAIL = "GET_INFO_FAIL";

export const SET_INFO_SUCCESS = "SET_INFO_SUCCESS";

export const REPORT_START = "REPORT_START";
export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAIL = "REPORT_FAIL";
export const REPORT_DOWNLOAD = "REPORT_DOWNLOAD";
export const REPORT_VIEW = "REPORT_VIEW";
export const REPORT_FLUSH = "REPORT_FLUSH";
