import React from "react";
import "./Neu_botton.scss";

const Neu_button = (props) => {


  // let isValid=props.isDisabled;

  let style = {};
  if (props._style === "main" || props._style === "green") {
    style = {
      color: "#1BAA8B",
    };
  }
  else if (props._style === "secondary" || props._style === "blue") {
    style = {
      color: "#23A8F2",
    };
  }
  else if (props._style === "danger" || props._style === "red") {
    style = {
      color: "#DC143C",
    };
  }
  else {
    style = {
      color: "#555",
    };
  }

  return (
    <>
      {props.isDisabled !== false ?
        <button
          theme={props.theme}
          className="Neu-btn"
          onClick={props.onClick}
          style={style}

        >
          <i className={props.icon}></i> &nbsp; {props.children}
        </button>
        :
        <button
          theme={props.theme}
          className="Neu-btn disabled"
          onClick={props.onClick}
          style={style}
          disabled

        >
          <i className={props.icon}></i> &nbsp; {props.children}
        </button>
      }

    </>
  );
};

export default Neu_button;
