import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, InfoWindow, MarkerF, useLoadScript } from "@react-google-maps/api";
import mapStyle__dark from "./MapStyle__dark"
import mapStyle__light from "./MapStyle__light"


import { isDateOlderThanThreeDays } from "../../utils";


const libraries = ["places"];


// const center = { lat: 27.652, lng: 78.526 }



function createCustomIcon(text, status) {
  const svgMarker = `<svg width="200" height="100" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="200" height="100" fill="${status}" rx="20" ry="20"/>
    <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#ffffff" font-size="50">${text}</text>
  </svg>`;

  const svgMarker2 = `<svg  width="300" height="290" viewBox="0 131.295 498.201 254.197" xmlns="http://www.w3.org/2000/svg">
  <path d="M 259.746 378.888 L 335.651 351.731 L 370.311 351.703 C 438.075 351.648 493.007 304.926 493.007 247.346 C 493.007 189.765 438.075 143.132 370.311 143.187 L 127.694 143.383 C 59.93 143.438 4.998 190.159 4.998 247.74 C 4.998 305.32 59.93 351.954 127.694 351.899 L 183.843 351.854 L 259.746 378.888 Z" style="stroke: rgb(0, 0, 0); paint-order: stroke; fill: ${status}; stroke-width: 6px; stroke-linecap: round; stroke-linejoin: bevel;" transform="matrix(1, 0, 0, 1, 0, 7.105427357601002e-15)"/>
  <text style="fill: rgb(0,0,0); font-family: Arial, sans-serif; font-size: 110px; font-weight:700; text-align:center; white-space: pre;" x="10%" y="277.579" >${text === "0" ? "000000" : text}</text>
</svg>`

  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgMarker2)}`,
    scaledSize: { width: 35, height: 35 },
  };
}



const MapsMultiMarker = React.memo(({ mapInfo }) => {
  let options = null, themeData;
  themeData = localStorage.getItem('theme-data');

  // alert(localStorage.getItem('theme-data'))
  if (themeData === "Dark") {
    options = {
      styles: mapStyle__dark,
      disableDefaultUI: true,
      zoomControl: true
    }
  }
  else {
    options = {
      styles: mapStyle__light,
      disableDefaultUI: true,
      zoomControl: true
    }
  }

  // console.log("%c" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "color:#0072bc");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  const [selected, setSelected] = useState(null)
  const [centerLoc, setCenterLoc] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(7)
  const [devices, setDevices] = useState(mapInfo)

  const searchInputRef = useRef();

  // console.log(props?.location)



  // let lat = props.location ? props.location[0] : "27";
  // let lng = props.location ? props.location[1] : "78";
  // lat = parseFloat(lat);
  // lng = parseFloat(lng);

  // console.log(lat)
  // console.log(lng)

  useEffect(() => {

  }, [])

  if (loadError) return "Error occured while loading map";
  if (!isLoaded) return "Loading Google Maps";

  const mapContainerStyle = {
    width: "100%", height: "100%",
  }


  const setSelectedIdHandler = (info) => {
    setSelected(info)
    setCenterLoc(info?.location)
  }

  const searchDeviceIdHandler = (id) => {
    let device = mapInfo.filter(device => device.deviceId.toLowerCase().includes(id.toLowerCase()));
    console.log(device)

    if (id === "") {
      setDevices(mapInfo)
      return
    }

    if (device) {
      setDevices(device)
      setCenterLoc(device?.location)
      // setZoomLevel(2000)
      // setSelected(device)
    }

  }

  const clearSearchHandler = () => {
    setDevices(mapInfo);
    searchInputRef.current.value = ""
  }


  return (

    <>
      <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>

        <input type="search" ref={searchInputRef} onChange={(e) => searchDeviceIdHandler(e.target.value)} placeholder="Search Device Id" style={{ borderRadius: "5px 0 0 5px", padding: "5px 10px", border: "2px solid #ddd" }} />
        <button onClick={() => { clearSearchHandler() }} style={{ height: "100%", padding: "5px 10px", border: "2px solid #ddd", borderRadius: " 0 5px 5px 0", }}>x</button>
      </div>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        // google={this.props.google}
        zoom={zoomLevel}
        // style={{ mapStyles }}
        center={{ lat: +centerLoc?.[0] || +devices[0]?.location[0] || 27, lng: +centerLoc?.[1] || +devices[0]?.location[1] || 78 }}
        options={options}
      >
        {
          devices?.map((info) =>

          (<MarkerF key={`${info?.deviceId}`} position={{ lat: +info?.location[0] || 27, lng: +info?.location[1] || 78 }}
            onClick={() => setSelectedIdHandler(info)}
            // icon={{
            //   url: isDateOlderThanThreeDays(new Date(info?.timestamp)) ? offline : online, // Path to your icons
            //   scaledSize: { width: 35, height: 35 },
            // }}
            icon={createCustomIcon(info?.paraInfo[0].value, isDateOlderThanThreeDays(new Date(info?.timestamp)) ? "#e37a7a" : "#37d88e")}
          >
            {
              selected?.deviceId === info.deviceId && (
                <InfoWindow onCloseClick={() => setSelected(null)} >
                  <div>
                    <div style={{ display: "flex", gap: "1rem", alignItems: "baseline" }}>
                      <h3 style={{ fontWeight: 'bold' }}>{info?.deviceId} </h3>
                      {(isDateOlderThanThreeDays(new Date(info?.timestamp)) ? <p style={{ padding: "5px", color: "#e37a7a" }}>offline</p> : <p style={{ padding: "5px", color: "#37d88e" }}>online</p>)}
                    </div>
                    <div style={{ borderTop: "2px solid #ccc", padding: "5px" }}>
                      {
                        info?.paraInfo.map(para => {
                          return (<div style={{ display: "flex", gap: "1rem" }}>
                            <p style={{ textTransform: "capitalize", fontWeight: "bold" }}>{para.parameter}:</p><p>{para.value}</p>
                          </div>)
                        })
                      }
                    </div>

                  </div>
                </InfoWindow>
              )
            }
          </MarkerF>
          )
          )
        }
      </GoogleMap>
    </>
  );

})




export default MapsMultiMarker;