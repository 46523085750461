import React, { Component } from 'react';
import "./notadded.css"
import { Link } from "react-router-dom";
// import Button from "../../UI/Button/Neu_button"

class NotAdded extends Component {
    render() {
        return (
            <div className="wrapperNoDevice">
                <i className="fad fa-robot notFoundIcon"></i>
                <br />
                <div className="noDeviceBody">
                    <h5 className="NotAddedTitle">It seems like you didn't add any device yet. You can go to
                        <Link className="ManageDeviceLink" to="/ManageDevices"> Manage Devices</Link>
                        to add your device.</h5>
                </div>
            </div>
        );
    }
}

export default NotAdded;
