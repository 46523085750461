import React, { Component } from 'react';
import "./Spinner2.scss";

export default class Spinner extends Component {
    render() {
        return (
            <div>
                <div id="building" theme={this.props.theme} style={{ fontSize: "12px" }}>
                    <div id="blocks">
                        <div className="b" theme={this.props.theme} id="b1"></div>
                        <div className="b" theme={this.props.theme} id="b2"></div>
                        <div className="b" theme={this.props.theme} id="b3"></div>
                        <div className="b" theme={this.props.theme} id="b4"></div>
                    </div>
                </div>
                <div id="caption" style={{ textAlign: "center" }}>
                    <p className="textAnimation">
                        Trying to Connect with the Server...
                    </p>
                </div>
            </div>
        )
    }
}
