import React, { Component } from 'react';
import "./SubNavBar.css"

class SubNavBar extends Component {

    render() {
        return (
            <div className="row info-panel" style={{ padding: "0 10px" }}>
                <div
                    className="col-md-4 col-xs-4 "
                    style={{
                        textAlign: "left",
                        padding: "0px 10px",
                        color: this.props.textColor,
                    }}
                >

                    {this.props.compName &&
                        <span
                            className="company-name badge-blue"
                        >
                            <i className="fad fa-user-tie"></i>{"  "} {this.props.compName.length > 19 ? this.props.compName.substr(0, 20) + "..." : this.props.compName}
                        </span>
                    }
                </div>
                <div
                    className="col-md-4 col-xs-4"
                    style={{
                        textAlign: "center",
                        padding: "0px 5px 0px 5px",
                        color: this.props.textColor,
                    }}
                >
                    {
                        this.props.lut &&
                        <span className="lut badge-green">
                            <i className="fad fa-clock"></i>{"  "}
                            <p id="showLastUpdateTime" style={{ display: "inline-block" }}>
                                {this.props.lut}
                            </p>
                        </span>
                    }
                </div>
                <div
                    className="col-md-4 col-xs-4"
                    style={{
                        textAlign: "right",
                        padding: "0px 10px 0px 0px",
                        color: this.props.textColor,
                    }}
                >
                    {/* {(this.props.ids[0]) && */}
                    <span
                        className="device-id badge-orange"
                    >
                        <select
                            id="deviceIdSelect"
                            onChange={this.props.onChange}
                        >
                            {this.props.ids.map(item => {
                                if (item.id)
                                    return (<option value={item.id} key={`id_${item.id}`}> {item.alias ? `${item.alias} (${item.id})` : `${item.id}`}</option>)
                                else
                                    return (<option value={item} key={`id_${item}`}>{item}</option>)

                            })}
                        </select>
                        {/* <i className="fad fa-tablet-android" style={{}}></i> WMS100234{" "}
              <i className="fas fa-sort-down" style={{}}></i>{" "} */}
                    </span >
                    {/* } */}
                </div >
            </div >
        );
    }
}

export default SubNavBar;