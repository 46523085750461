import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility"

const initialState = {
    token: null,
    devices: null,
    error: null,
    loading: null,
    message: null
};

const authStart = (state) => {
    return updateObject(state, { error: null, loading: true, message: null })
}
const authSuccess = (state, action) => {
    return updateObject(state, { token: action.idToken, devices: action.devices, error: null, loading: false, message: action.message })
}
const authFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}
const authLogout = (state) => {
    return updateObject(state, { token: null, devices: null })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action)
        case actionTypes.AUTH_LOGOUT: return authLogout(state)
        default: return state;
    }
}

export default reducer;