import React from "react";

class DataCard extends React.Component {
  render() {
    return (
      // <SwiperSlide>
      <div
        className="dataCard demo-card-wide _card swiper-slide"
        data-color={this.props.themecolor} width={this.props.size}
        style={{ width: "100%!important" }}
      >
        <div className="innerContent">
          <div className="_dataHeader">
            <h6 className="paraName">{this.props.Name}</h6>
          </div>
          <div className="_dataBody">
            {" "}
            <h2 className="paraValue">{this.props.Data}</h2>{" "}
            <small className="paraUnit">{this.props.Unit}</small>
          </div>
        </div>
      </div>
      // </SwiperSlide>
    );
  }
};

export default DataCard;
