import React, { Component } from 'react';
import "./Datebox.css";


class datebox extends Component {

    render() {
        return (<>

            {!this.props.style &&
                <span className="date-wrapper">
                    <input
                        type="date"
                        id={this.props.id}
                        className="date-box"
                    />
                </span>
            }
            {this.props.style === 'neumorphic' &&
                <span className="neu-date-wrapper">
                    <input
                        type="date"
                        id={this.props.id}
                        className="date-box"
                    />
                </span>
            }
        </>);
    }
}

export default datebox;