import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility"

const initialState = {
    name: null, email: null, phone: null, firm_name: null, address: null, geo_coordinates: null, profile_icon: null, error: null, loading: false,
};

const getInfoStart = (state) => {
    return updateObject(state, { error: null, loading: true })
}
const getInfoSuccess = (state, action) => {
    return updateObject(state, { name: action.name, email: action.email, phone: action.phone, firm_name: action.firm_name, address: action.address, geo_coordinates: action.geo_coordinates, profile_icon: action.profile_icon, error: null, loading: false })
}
const getInfoFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const setInfoSuccess = (state, action) => {
    return updateObject(state, { name: action.name, email: action.email, phone: action.phone, firm_name: action.firm_name, address: action.address, geo_coordinates: action.geo_coordinates, profile_icon: action.profile_icon, error: null, loading: false })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INFO_START: return getInfoStart(state)
        case actionTypes.GET_INFO_SUCCESS: return getInfoSuccess(state, action)
        case actionTypes.GET_INFO_FAIL: return getInfoFail(state, action)
        case actionTypes.SET_INFO_SUCCESS: return setInfoSuccess(state, action)
        default: return state;
    }
}

export default reducer;