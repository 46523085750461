import React from "react";
// import "./Textbox.scss";

const Textbox = (props) => {
  let style = props.style;


  return (
    <input
      theme={props.theme}
      style={style}
      className="ee_input"
      type={props.type}
      id={props.id}
      placeholder={props.placeholder}
      autoComplete="false"
      onChange={(e) => props.change(e)}
      value={props.value}
    />
  );
};

export default Textbox;
