import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "var(--theme-color)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "var(--background)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "var(--background-sec)"
    }
  },
}))(TableRow);



//console.log(rows);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
// });

function table(props) {

  let keys = {}
  try {
    keys = Object.keys(props.rows[0]);

  }
  catch (e) {
    keys = ["-", "-", "-"];
    console.error(e);
  }
  // const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 200 }} aria-label="customized table" className="genTable">
        <TableHead className="tableHeader">
          <TableRow>
            {props.headers.map((header) => (
              <StyledTableCell style={{ fontSize: "12px" }} key={header}>{header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            < StyledTableRow key={`dataRow_${row}_${i}`} >
              {
                keys.map((cell, index) => (
                  <StyledTableCell component="th" scope="row" key={`dataCell_${cell}_${index}`}>
                    {" " + row[cell] + " "}
                  </StyledTableCell>
                ))
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

export default table;
