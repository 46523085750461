import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import { isDateOlderThanThreeDays } from "../../../utils";

// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

// import AllDeviceMap from "./AllDeviceMap";
import Maps from "../../Maps/MapsMultiMarker";
import "./TableLayout.css";

const TableLayout = (props) => {
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [tableHeader, setTableHeader] = useState([]);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ keyToSort: "", direction: "ASC" });
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("parameter");
  const [mapInfo, setMapInfo] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    let devices = JSON.parse(localStorage.getItem("devices"))?.devices;

    // console.log(devices);
    let types = devices.reduce((acc, device) => {
      let type = extractAlphabets(device.deviceDetails.device_id);

      if (!acc.includes(type)) {
        acc = [...acc, type];
      }
      return acc;
    }, []);

    setTypes(types);
    setSelectedType(types[0]);

    props.checkAuthStatus();
    let authCheckInterval = setInterval(() => {
      props.checkAuthStatus();
      // console.log("checkAuth | TableLayout");
    }, 1000);

    const userDetails = devices.userDetails;

    const isMobileView = window.matchMedia("(max-width: 768px)").matches;

    if (isMobileView) {
      // Code for mobile view
      document.getElementById("sidebar").style = "width:0";
    }

    return () => {
      clearInterval(authCheckInterval);
    };
  }, []);

  useEffect(() => {
    console.log(selectedType);

    let prevParaAsPerType =
      JSON.parse(localStorage.getItem("visibleParaForType")) || {};

    console.log(prevParaAsPerType);

    if (selectedType) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/devices/getDataByType/${selectedType}`,
          {
            headers: {
              "x-api-key": token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res?.status >= 200 && res?.status <= 399) {
            //   setDeviceData(res?.data);
            let data = res?.data;
            console.log(data);
            let displayInfo = data.map((device, index) => {
              console.log(device);
              return {
                location: device.find((para) => para.parameter === "location")
                  .value,
                deviceId: device.find((para) => para.parameter === "device id")
                  .value,
                timestamp: device.find((para) => para.parameter === "Timestamp")
                  .value,
                paraInfo: device.filter(
                  (para) =>
                    para.parameter !== "device id" &&
                    para.parameter !== "location"
                ),
              };
            });

            console.log(displayInfo);

            // console.log(locationData);
            setMapInfo(displayInfo);

            if (
              prevParaAsPerType[selectedType] &&
              prevParaAsPerType[selectedType].length
            ) {
              console.log({ dataAllHeader: data });
              let header = data[0]
                .map(
                  (header) =>
                    (prevParaAsPerType[selectedType]?.includes(
                      header?.parameter.split(" ").join("_").toLowerCase()
                    ) ||
                      header?.parameter === "Timestamp" ||
                      header?.parameter === "device id" ||
                      header?.parameter === "location") &&
                    header?.parameter
                )
                .filter((data) => data);

              console.log(header);
              setTableHeader(header);

              let filterData = data.map((data) =>
                data.filter((col) => {
                  return (
                    (prevParaAsPerType[selectedType]?.includes(
                      col?.parameter.split(" ").join("_").toLowerCase()
                    ) ||
                      col?.parameter === "Timestamp" ||
                      col?.parameter === "device id" ||
                      col?.parameter === "location") &&
                    col
                  );
                })
              );
              console.log(filterData);
              setData(filterData);
            } else {
              let header = data[0].map((header) => header?.parameter);
              console.log(header);
              setTableHeader(header);
              setData(data);
            }
          } else {
            console.log(res);
          }
        });
    }
  }, [selectedType]);

  function extractAlphabets(inputString) {
    // Use a regular expression to match only alphabets
    const alphabetsOnly = inputString.replace(/[^a-zA-Z]/g, "");
    return alphabetsOnly;
  }

  const headerClickHandler = (header) => {
    alert(header);
    setSort({
      keyToSort: header,
      direction:
        header === sort.keyToSort
          ? sort.direction === "ASC"
            ? "DESC"
            : "ASC"
          : "DESC",
    });
  };

  const getSortedArray = (data) => {
    alert(sort.direction);

    if (sort.direction === "ASC") {
      return data.sort((a, b) =>
        a[sort.keyToSort] > b[sort.keyToSort] ? 1 : -1
      );
    }
    return data.sort((a, b) =>
      a[sort.keyToSort] > b[sort.keyToSort] ? -1 : 1
    );
  };

  const handleSort = (parameter) => {
    console.log({ parameter });
    const order = sortOrder === "asc" ? "desc" : "asc";

    const sorted = [...data].sort((a, b) => {
      console.log({ a, b });
      const aValue = a.find((item) => item?.parameter === parameter)?.value;
      const bValue = b.find((item) => item?.parameter === parameter)?.value;

      if (parameter === "Timestamp") {
        // Parse dates and compare numerically
        const dateA = new Date(aValue).getTime();
        const dateB = new Date(bValue).getTime();
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        // Use localeCompare for string comparison
        return order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });

    setData(sorted);
    setSortOrder(order);
    setSortBy(parameter);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          padding: "2rem",
        }}
      >
        <select
          name=""
          id=""
          style={{
            backgroundColor: "dodgerblue",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
          }}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {types.map((type) => (
            <option key={type}>{type}</option>
          ))}
        </select>
      </div>

      <div
        style={{
          height: "60vh",
          // padding: "2rem",
          border: "2px solid #ccc",
          margin: "2rem",
        }}
      >
        {mapInfo.length ? <Maps mapInfo={mapInfo} /> : <p>Loading...</p>}
      </div>

      {/* <AllDeviceMap /> */}

      <div
        style={{
          padding: "2rem",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <table
          className="table"
          style={{ maxHeight: "100vh", overflow: "auto" }}
        >
          <thead
          // style={{
          //   position: "sticky",
          //   top: "0",
          //   zIndex: "20",
          //   width: "100%",
          // }}
          >
            <tr>
              {tableHeader.map((header) => (
                <th
                  key={header}
                  style={{
                    textTransform: "capitalize",
                    color: "#000",
                    fontSize: "1.8rem",
                  }}
                  onClick={() => handleSort(header)}
                >
                  {header}{" "}
                  {header !== "location" && (
                    <span>
                      {sortOrder === "asc" && (
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none",
                          }}
                        >
                          🔽
                        </button>
                      )}
                      {sortOrder === "desc" && (
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none",
                          }}
                        >
                          🔼
                        </button>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => {
              return (
                <tr key={`row_${rowIndex}_${row[rowIndex]?.parameter}`}>
                  {row.map((col) => (
                    <td key={`col_${rowIndex}_${col?.parameter}`}>
                      <span style={{ display: "flex" }}>
                        {console.log({
                          paraType: typeof col?.value,
                          paraValue: col?.value,
                        })}
                        {col?.parameter !== "location" &&
                        typeof col?.value !== "object"
                          ? col?.value
                          : col?.value?.map((item) => (
                              <span style={{ paddingRight: "10px" }} key={item}>
                                {item}
                              </span>
                            ))}
                        {col?.parameter === "Timestamp" &&
                          isDateOlderThanThreeDays(new Date(col?.value)) && (
                            <span
                              style={{
                                backgroundColor: "red",
                                width: "5px",
                                height: "5px",
                                padding: "5px",
                                borderRadius: "20px",
                                color: "white",
                                marginLeft: "5px",
                              }}
                            ></span>
                          )}
                        {col?.parameter === "location" && (
                          <span>
                            <a
                              href={`https://www.google.com/maps/place/${parseFloat(
                                col?.value[0]
                              )},${parseFloat(col?.value[1])}/@${parseFloat(
                                col?.value[0]
                              )},${parseFloat(col?.value[1])},15z?entry=ttu`}
                              target="_blank"
                              style={{ margin: 0, fontSize: "1.6rem" }}
                            >
                              Open Google Maps
                            </a>
                          </span>
                        )}
                      </span>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { checkAuthStatus: () => dispatch(actions.authCheckState()) };
};

export default connect(null, mapDispatchToProps)(TableLayout);
