import React, { Component } from 'react';
import DataCard from '../Datacard/Datacard';
// import Swiper from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, {
    Autoplay,
    EffectCoverflow, Pagination
} from 'swiper/core';
import axios from "axios";
import "./DynamicCards.css"

SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);

class DynamicCards extends Component {


    refreshTimer = null;

    themeArray = [
        "purpleLove",
        "harmonic",
        "emerald",
        "sunnyDays",
        "sexyBlue",
        "harvey",
        "orange",
        "pink",
        "red",
        "sky",
        "dawn",
        "purple",
        "blue",
        "megaTron",
    ];

    state = { parameters: [] }

    dataTimeout = null

    componentDidMount() {
        // setInterval(() => {
        const getCardsData = () => {

            let prevParaAsPerType =
                JSON.parse(localStorage.getItem("visibleParaForType")) || {};

            console.log(prevParaAsPerType);
            //   console.log(this.props.token);
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/devices/getDataForCards/${this.props.selectedId}`, {
                    headers: {
                        'x-api-key': this.props.token
                    }
                }
                )
                .then(
                    (res) => {
                        if (!res.data.error) {
                            this.setState({ lut: res.data[res.data.length - 1] });

                            let para = res.data.slice(0, res.data.length - 1);
                            let type = extractAlphabets(this.props.selectedId);
                            console.log({ prevPara: para })

                            console.log(para[0].parameter.split(" ").join("_").toLowerCase())

                            if (prevParaAsPerType[type] && prevParaAsPerType[type].length) {
                                para = para.filter(
                                    (header) =>
                                    (prevParaAsPerType[type]?.includes(
                                        header?.parameter.split(" ").join("_").toLowerCase()
                                    ) ||
                                        header?.parameter === "Timestamp" ||
                                        header?.parameter === "device id" ||
                                        header?.parameter === "location")
                                )
                            }

                            console.log({ currPara: para })

                            this.setState({ parameters: para });
                            if (!this.firstTime) {
                                // new Swiper(".swiper1", {
                                //     effect: "coverflow",
                                //     grabCursor: true,
                                //     centeredSlides: true,
                                //     slidesPerView: "auto",
                                //     coverflowEffect: {
                                //         rotate: 30,
                                //         stretch: 40,
                                //         depth: 160,
                                //         modifier: 1,
                                //         slideShadows: true,
                                //     },
                                //     rebuildOnUpdate: true,
                                //     autoplay: {
                                //         delay: 2500,
                                //         disableOnInteraction: false,
                                //     },
                                //     loop: true,
                                //     pagination: {
                                //         el: ".swiper-pagination1",
                                //     },
                                //     paginationClickable: true,
                                // });
                            }
                            this.firstTime = true;
                        }
                    });
            this.dataTimeout = setTimeout(getCardsData, 10000);
            // }, 1000);
        }

        this.dataTimeout = setTimeout(getCardsData, 200);

        function extractAlphabets(inputString) {
            // Use a regular expression to match only alphabets
            const alphabetsOnly = inputString.replace(/[^a-zA-Z]/g, "");
            return alphabetsOnly;
        }
    }




    componentWillUnmount() {
        // Clear the timeout using the stored ID
        if (this.dataTimeout) {
            clearTimeout(this.dataTimeout);
        }
    }

    render() {
        const _dataCards = this.state.parameters.map((parameters, index) => {

            // just because backend dev gone mad and inserted comments inside parameter value. 😣
            if (parameters.value.toString().includes('APCSV-APCSV-DC1-')) {
                parameters.value = parameters.value.toString().replace("APCSV-APCSV-DC1-", "");
            }
            else if (parameters.value.toString().includes('APCSV-APCSV-DC2-')) {
                parameters.value = parameters.value.toString().replace("APCSV-APCSV-DC2-", "");
            }

            return (
                <SwiperSlide key={`sws_${parameters.parameter}_${index}`}>
                    <DataCard
                        key={`Dc_${parameters.parameter}_${index}`}
                        themecolor={this.themeArray[index]}
                        Name={parameters.parameter}
                        Data={parameters.value}
                        Unit={parameters.unit}
                    />
                </SwiperSlide>
            );
        });

        return (
            <>
                {!(this.state.parameters.length <= 2) ?
                    <div className="swiper-container swiper1">
                        {/* <div className="swiper-wrapper" id="swiper_con1"> */}
                        <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={'auto'} coverflowEffect={{
                            "rotate": 30,
                            "stretch": 40,
                            "depth": 160,
                            "modifier": 1,
                            "slideShadows": true
                        }}
                            loop={true}
                            autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": true
                            }}
                            pagination={{
                                "clickable": true
                            }} className="mySwiper">
                            {_dataCards}
                        </Swiper>
                        {/* </div> */}
                        <div className="swiper-pagination swiper-pagination1" style={{ bottom: "-5px" }}></div>
                    </div>
                    :
                    <div className="row">
                        {this.state.parameters.map((parameters, index) => {
                            // just because backend dev gone mad and inserted comments inside parameter value. 😣
                            if (parameters.value.toString().includes('APCSV-APCSV-DC1-')) {
                                parameters.value = parameters.value.toString().replace("APCSV-APCSV-DC1-", "");
                            }
                            else if (parameters.value.toString().includes('APCSV-APCSV-DC2-')) {
                                parameters.value = parameters.value.toString().replace("APCSV-APCSV-DC2-", "");
                            }

                            return (
                                <div key={`Div_${index}`} className="col">
                                    <DataCard
                                        key={`DataCard_${index}`}
                                        themecolor={this.themeArray[index]}
                                        Name={parameters.parameter}
                                        Data={parameters.value}
                                        Unit={parameters.unit}
                                        size="100"
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </>
        );
    }
}

export default DynamicCards;