import React, { Component } from "react";
import NeuButton from "../../Components/UI/Button/Neu_button";
import TextBox from "../../Components/Textbox/Textbox";
import axios from "axios";
import "./ManageDevices.scss";
import Cookies from "universal-cookie";
// import SetLocation from "../../Components/SetLocation/SetLocation"
import MapGetLocation from "../../Components/Maps/MapsGetLocation";

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

class ManageDevices extends Component {

  cookies = new Cookies();

  token = localStorage.getItem('token');

  initialState = {
    successMsg: null,
    errorMsg: null,
    showSuccess: true,
    showError: true,
    ids: []
  }


  constructor(props) {
    super(props)
    this.state = this.initialState
  }


  resetMsgState() {
    this.setState({
      successMsg: null,
      errorMsg: null,
      showSuccess: true,
      showError: true,
    });
  }


  componentDidMount() {

    const isMobileView = window.matchMedia("(max-width: 768px)").matches;

    if (isMobileView) {
      // Code for mobile view
      document.getElementById("sidebar").style = "width:0"
    }

    this.props.checkAuthStatus();
    setInterval(() => {
      this.props.checkAuthStatus();
      console.log("AuthCheck")
    }, 10000);


    if (this.state.ids.length < 1) {
      document.getElementById('addDeviceContainer').classList.add("col-xs-offset-4");
    }
    console.log(this.state.ids[0])
    if (this.state.ids[0]) {
      document.getElementById('aliasName').value = this.state.ids[0].alias;
      document.getElementById('mDDelAliasName').innerHTML = this.state.ids[0].alias;
    }
  }


  addDeviceHandler = () => {
    this.resetMsgState();
    let productId = document.getElementById('addProductId');
    let serialNo = document.getElementById('addSerialNo');

    console.log(productId.value + "  " + serialNo.value)

    let url = `${process.env.REACT_APP_BASE_URL}/users/add-device`;
    let addDeviceInfo = {
      device_id: productId.value,
      serial_no: serialNo.value
    }

    axios.patch(url, addDeviceInfo, {
      headers: {
        'x-api-key': this.token
      }
    })
      .then(response => {
        document.getElementById('addDeviceContainer').classList.remove("col-xs-offset-4");
        console.log(response.data)
        let data = JSON.parse(localStorage.getItem('devices'));
        data.devices = response.data.devices;
        localStorage.setItem('devices', JSON.stringify(data));
        this.setState({ successMsg: response.data.msg })
      })
      .catch(error => {
        console.log({ error })
        try {
          console.log(error.response.data.error);
          this.setState({ errorMsg: error.response.data.error });
        }
        catch (e) {
          console.log({ e })
        }
      })
  }

  deleteDeviceHandler = () => {
    this.resetMsgState();
    let productId = document.getElementById("mDDelDevId");
    //alert(productId.value);
    // console.log(this.token)
    let url = `${process.env.REACT_APP_BASE_URL}/users/delete-device`;

    axios.delete(url, {
      headers: {
        'x-api-key': this.token
      },
      data: {
        device_id: productId.value
      }
    })
      .then(response => {
        // document.getElementById('addDeviceContainer').classList.add("col-xs-offset-4");
        console.log(response.data)
        let data = JSON.parse(localStorage.getItem('devices'));
        data.devices = response.data.devices;
        localStorage.setItem('devices', JSON.stringify(data));
        this.setState({ successMsg: response.data.msg })
        this.cookies.remove('selectedId')
      })
      .catch(error => {
        try {
          console.log(error.response.data.error);
          this.setState({ errorMsg: error.response.data.error });
        }
        catch (e) {
          console.log(e)
        }
      })
  }
  setRespectiveAlias = (event) => {
    document.getElementById('aliasName').value = this.state.ids[event.target.selectedIndex].alias;
  }
  setRespectiveAliasDel = (event) => {
    document.getElementById('mDDelAliasName').innerHTML = this.state.ids[event.target.selectedIndex].alias;
  }



  aliasDeviceHandler = () => {
    this.resetMsgState();
    // this.setState({ errorMsg: 'Sorry! Feature not Implemented' });
    let productId = document.getElementById("aliasProductId");
    let alias = document.getElementById("aliasName");
    //alert(productId.value);
    // console.log(this.token)
    let url = `${process.env.REACT_APP_BASE_URL}/users/device-alias`;

    let aliasDeviceInfo = {
      device_id: productId.value,
      alias: alias.value
    }

    axios.patch(url, aliasDeviceInfo, {
      headers: {
        'x-api-key': this.token
      }
    })
      .then(response => {
        // document.getElementById('addDeviceContainer').classList.add("col-xs-offset-4");
        console.log(response.data)
        let data = JSON.parse(localStorage.getItem('devices'));
        data.devices = response.data.devices;
        localStorage.setItem('devices', JSON.stringify(data));
        this.setState({ successMsg: response.data.msg })
      })
      .catch(error => {
        try {
          console.log(error.response.data.error);
          this.setState({ errorMsg: error.response.data.error });
        }
        catch (e) {
          console.log(e)
        }
      })
  }

  render() {
    const devices = JSON.parse(localStorage.getItem('devices'));
    this.state.ids = devices.devices.map(item => { return { "alias": item.deviceDetails.alias, "id": item.deviceDetails.device_id } });

    console.log(this.state.ids)

    let successMsg = null, errorMsg = null;

    if (this.state.successMsg && this.state.showSuccess) {
      successMsg = <p className="successMsg animated flipInX">{this.state.successMsg}</p>;
      setTimeout(() => { this.setState({ showSuccess: false }); }, 2500)
    }
    if (this.state.errorMsg && this.state.showError) {
      errorMsg = (<p className="errorMsg animated flipInX ">{this.state.errorMsg}</p>);
      setTimeout(() => { this.setState({ showError: false }); }, 2500)
    }

    return (
      <div>
        {successMsg}
        {errorMsg}
        <div className="row " style={{ margin: "20px", marginTop: 40 }}>
          <h4 className="pageTitle" color="light">Manage Device</h4>
          <div className="col-md-4 " id="addDeviceContainer"  >
            <div
              className="demo-card-wide neu_card mdl-card"
              themeColor={this.props.dataColor}>
              <h4>Add Your Device </h4>
              <br />
              {/* <form> */}
              <TextBox
                id='addProductId'
                theme={this.props.dataColor}
                placeholder="Enter Product ID"
                type="text"
              ></TextBox>
              <TextBox
                id='addSerialNo'
                theme={this.props.dataColor}
                placeholder="Enter Serial No."
                type="text"
              ></TextBox>
              <br />
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "right",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <NeuButton
                  theme={this.props.dataColor}
                  // Text="Add "
                  _style="main"
                  icon="fad fa-plus-circle"
                  onClick={this.addDeviceHandler}
                >
                  Add
                </NeuButton>
              </div>
              {/* </form> */}
            </div>
          </div>

          {this.state.ids.length > 0 &&

            <div className="col-md-4  " style={{ display: "inline" }}>
              <div
                className="demo-card-wide neu_card mdl-card"
                themeColor={this.props.dataColor}
              >
                <h4>Delete Your Device </h4>
                <br />

                <div className="mdDelDev" theme={this.props.dataColor}>
                  <select
                    id="mDDelDevId"
                    onChange={(e) => this.setRespectiveAliasDel(e)}
                  >
                    {this.state.ids.map(item => {
                      // console.log(item.id)
                      return (<option value={item.id} key={`id_${item.id}`}>{item.id}</option>)
                    })}
                  </select>
                </div>
                <br />
                <p id="mDDelAliasName"></p>
                <br />

                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "right",
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <NeuButton
                    theme={this.props.dataColor}
                    _style="danger"
                    icon="fad fa-trash-alt"
                    onClick={this.deleteDeviceHandler}
                  >
                    Delete
                  </NeuButton>
                </div>
              </div>
            </div>
          }
          {this.state.ids.length > 0 &&
            <div className="col-md-4  " style={{ display: "inline" }}>
              <div
                className="demo-card-wide neu_card mdl-card"
                themeColor={this.props.dataColor}
              >
                {/* <form> */}
                <h4>Alias Your Device </h4>
                <br />
                <div className="mdDelDev" theme={this.props.dataColor}>
                  <select
                    id="aliasProductId"
                    onChange={(e) => this.setRespectiveAlias(e)}

                  >
                    {this.state.ids.map(item => {
                      // console.log(item.id)
                      return (<option value={item.id} key={`id_${item.id}`}>{item.id}</option>)
                    })}

                  </select>
                </div>

                <TextBox
                  id='aliasName'
                  theme={this.props.dataColor}
                  placeholder="Enter Alias Name"
                  type="text"
                ></TextBox>
                <br />
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "right",
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <NeuButton
                    theme={this.props.dataColor}
                    _style="secondary"

                    icon="fad fa-file-invoice"
                    onClick={this.aliasDeviceHandler}
                  >Alias</NeuButton>
                </div>
                {/* </form> */}
              </div>
            </div>
          }
        </div>
        <div className="row" style={{ margin: "20px" }}>
          <div className="col-md-12">
            <div
              className="demo-card-wide neu_card mdl-card" style={{ minHeight: "500px", overflowY: "auto" }}
              themeColor={this.props.dataColor}
            >
              <h4>Add Your Device Location </h4>
              <br />
              <div style={{ width: "100%", minHeight: "200px" }}>
                <MapGetLocation dataColor={this.props.dataColor} ids={this.state.ids} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row " style={{ margin: "40px" }}></div> */}
      </div >
    );
  }
}


const mapDispatchToProps = dispatch => {
  return { checkAuthStatus: () => dispatch(actions.authCheckState()) }
}


export default connect(null, mapDispatchToProps)(ManageDevices);
