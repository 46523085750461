import React, { Component } from 'react';
import "./Profile.scss";
import bg from "../../assets/img/bg_profile4.png";
import Table from "../../Components/Table/Table"
import ShowLayout from "./Layouts/ShowLayout"
import EditLayout from "./Layouts/EditLayout"
import axios from "axios";
import defaultProfile from "../../assets/img/default-avatar.png"
import Spinner from "../../Components/UI/Spinner2/Spinner2"
import * as actionTypes from "../../store/actions/actionTypes"
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";


class Profile extends Component {

    state = {
        isEdit: false,
        successMsg: null,
        errorMsg: null,
        showSuccess: true,
        showError: true,
    }



    device = JSON.parse(localStorage.getItem('devices'));
    token = localStorage.getItem('token') ? localStorage.getItem('token') : null;

    resetMsgState() {
        this.setState({
            successMsg: null,
            errorMsg: null,
            showSuccess: true,
            showError: true,
        });
    }


    changeEditState = () => {
        if (!this.state.isEdit) {
            this.setState({
                isEdit: true
            });
        }

        if (this.state.isEdit) {

            this.resetMsgState();
            let password = document.getElementById('profile-password');
            let firmName = document.getElementById('profile-firmName');
            let name = document.getElementById('profile-name');
            let address = document.getElementById('profile-address');
            let companyLogo = document.getElementById('logoDataUrl');

            if (password.value) {

                const editData = {
                    password: password.value,
                    firm_name: firmName.value,
                    name: name.value,
                    address: address.value,
                    profile_icon: companyLogo.value,
                    geo_coordinates: [20, 50]
                }

                console.log(editData)
                const header = {
                    headers: {
                        'x-api-key': this.token,
                        'Content-Type': 'application/json'
                    }
                };

                axios
                    .post(
                        `${process.env.REACT_APP_BASE_URL}/users/edit-profile`, editData, header
                    ).then(res => {
                        window.scrollBy(0, -1000);
                        this.setState({ successMsg: res.data.msg });
                        console.log(res.data);
                        setTimeout(() => { window.location.reload(); }, 2000);
                    })
                    .catch(err => {
                        console.log({ err })
                        window.scrollBy(0, -1000);
                        // this.setState({ errorMsg: err.response.data.error });
                    })
                this.setState({ isEdit: false })
            }
            else {
                // this.setState({ errorMsg: "Please Enter Password for Authenitcation" });
                password.classList.add('Invalid');
            }
        }
    }



    componentDidMount() {


        const isMobileView = window.matchMedia("(max-width: 768px)").matches;

        if (isMobileView) {
            // Code for mobile view
            document.getElementById("sidebar").style = "width:0"
        }
        // axios
        //     .get(
        //         `https://api.thingzcloud.com/users/get-profile`, {
        //         headers: {
        //             'x-api-key': this.token
        //         }
        //     }).then(res => {
        //         console.log(res.data.name);
        //         this.setState({
        //             name: res.data.name,
        //             firmName: res.data.firm_name,
        //             address: res.data.address,
        //             logo: res.data.profile_icon,
        //             email: res.data.email,
        //             phone: res.data.phone
        //         }, () => {
        //             console.log(this.state)
        //         });
        //         console.log(res.data.profile_icon);
        //         if (res.data.profile_icon)
        //             document.getElementById('img_companyLogo').src = res.data.profile_icon;
        //     })
        //     .catch(err => {
        //         console.log(err.data);
        //     })



        this.props.checkAuthStatus();
        window.addEventListener('load', this.props.onGetInfo());
        console.log(this.props.profile_icon);
        setTimeout(() => {
            try {
                console.log(this.props.profile_icon);
                if (this.props.profile_icon)
                    document.getElementById('img_companyLogo').src = this.props.profile_icon;
            }
            catch (e) {

            }
        }, 2000);
    }

    render() {

        // const header = ["DEVICE ID", "SERVICE START", "SERVICE END", "STATUS", "DAYS LEFT"];
        let header, body;
        if (this.device.devices[0]) {
            header = Object.keys(this.device.devices[0].deviceDetails);
            header = [...header, ...Object.keys(this.device.devices[0].serviceDetails)];
            header = header.map(item => item.toUpperCase().replace('_', ' '));
            // header =[header.]
            console.log(header)
            // header.push('DAYS LEFT');

            body = this.device.devices.map((item) => {
                // const endDate = new Date(item.service_end);
                // const now = new Date();
                // const daysDiff = Math.ceil(Math.abs(endDate - now) / (1000 * 60 * 60 * 24))
                return {
                    ...item.deviceDetails, ...item.serviceDetails
                }
            })

        }
        let iconClass = !this.state.isEdit ? "fad fa-edit" : "fad fa-save";
        let buttonTitle = !this.state.isEdit ? "Edit" : "Save";

        let successMsg = null, errorMsg = null;

        if (this.state.successMsg && this.state.showSuccess) {
            successMsg = <p className="successMsg animated flipInX">{this.state.successMsg}</p>;
            setTimeout(() => { this.setState({ showSuccess: false }); }, 2500)
        }
        if (this.state.errorMsg && this.state.showError) {
            errorMsg = (<p className="errorMsg animated flipInX ">{this.state.errorMsg}</p>);
            setTimeout(() => { this.setState({ showError: false }); }, 2500)
        }

        let component = null;

        { component = !this.state.isEdit ? <ShowLayout theme={this.props.theme} /> : <EditLayout theme={this.props.theme} /> }

        if (this.props.loading) {
            component = <Spinner theme="blue" />
        }


        return (
            <div>
                {successMsg}
                {errorMsg}
                <div className="profile">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-cover-image" style={{ backgroundImage: `url(${bg})` }}>
                                <h4 className="pageTitle">Profile</h4>
                            </div>
                            <div className="profile-logo-container">
                                <img src={defaultProfile} id="img_companyLogo" style={{ width: "150px", borderRadius: "70px", backgroundColor: "#fffa" }} />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: "40px" }}>
                        <div className="col-md-12 " id="addDeviceContainer"  >
                            <div className="profile-user-info">
                                <p>User Details</p>
                                <div className="demo-card-wide neu_card mdl-card profile-user-card"
                                    themeColor={this.props.theme}>
                                    {component}
                                    {/* {!this.state.isEdit ? <ShowLayout theme={this.props.theme} /> : <EditLayout theme={this.props.theme} />} */}
                                    <button className="circular-button" onClick={this.changeEditState}><i className={iconClass}></i>{""} {buttonTitle}</button>
                                </div>
                            </div>
                            {
                                this.device.devices[0] &&
                                <div className="profile-service-info">
                                    <p>Service Details</p>
                                    <Table headers={header} rows={body} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile_icon: state.profile.profile_icon, error: state.profile.error, loading: state.profile.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return { onGetInfo: () => dispatch(actions.getUserInfo()), checkAuthStatus: () => dispatch(actions.authCheckState()) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);