import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import mapStyle__dark from "./MapStyle__dark"
import mapStyle__light from "./MapStyle__light"


const libraries = ["places"];


// const center = { lat: 27.652, lng: 78.526 }



export default function (props) {
  let options = null, themeData;
  themeData = localStorage.getItem('theme-data');

  // alert(localStorage.getItem('theme-data'))
  if (themeData === "Dark") {
    options = {
      styles: mapStyle__dark,
      disableDefaultUI: true,
      zoomControl: true
    }
  }
  else {
    options = {
      styles: mapStyle__light,
      disableDefaultUI: true,
      zoomControl: true
    }
  }

  // console.log("%c" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "color:#0072bc");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  let lat = props.location ? props.location[0] : "27";
  let lng = props.location ? props.location[1] : "78";
  lat = parseFloat(lat);
  lng = parseFloat(lng);

  console.log(lat)
  console.log(lng)

  if (loadError) return "Error occured while loading map";
  if (!isLoaded) return "Loading Google Maps";

  const mapContainerStyle = {
    width: "100%", height: "100%",
  }
  return (

    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        // google={this.props.google}
        zoom={8}
        // style={{ mapStyles }}
        center={{ lat: lat, lng: lng }}
        options={options}
      >
        <Marker position={{ lat: lat, lng: lng }} />
      </GoogleMap>
    </>
  );

}