import React, { Component } from "react";
import "./Nav.css"




class Nav extends Component {

  userDetails = JSON.parse(localStorage.getItem('devices')).userDetails;
  state = {
    firmName: null,
    address: null,
    logo: null
  }

  componentDidMount() {

    console.log(this.userDetails)
    this.setState({
      firmName: this.userDetails.firm_name,
      address: this.userDetails.address,
      logo: this.userDetails.profile_icon
    }, () => {
      console.log(this.state.address)
    })

  }
  navToggleHandler = () => {
    // alert(1)
    // alert(document.getElementById("sidebar").style.width)
    if (document.getElementById("sidebar").style.width === "0px" || document.getElementById("sidebar").style.width === "") {

      document.getElementById("sidebar").style.width = "300px";
      document.getElementById("sidebar").style.zIndex = "10";
      document.getElementById("main-panel").style.width = "calc(100%-300px)";
      // document.querySelector(".sidebar .nav p")[0].style.width = "100%";
    }
    else {
      document.getElementById("sidebar").style.width = "0px";
      document.getElementById("sidebar").style.zIndex = "0";
      document.getElementById("main-panel").style.width = "calc(100%-0px)";
      // document.querySelector(".sidebar .nav p")[0].style.width = "0%";
    }
  }

  render() {

    return (
      <nav className="navbar navbar-default navbar-fixed" id="navbarHeader">
        <div className="container-fluid">
          <div className="navbar-header" >
            <button
              className="navbar-toggle"
              onClick={this.navToggleHandler}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              {/* <i className="fas fa-bars"></i> */}
            </button>
            <div className="nav-wrapper">

              <div className="companyLogo">
                {this.state.logo && <img alt="companyLogo" src={this.state.logo} style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}
              </div>

              <div className="companyInfo">
                <a className="nav-firmName" href="www.enggenv.com" style={{ display: "inline-block" }} >
                  {!this.state.firmName ? "Dashboard" : this.state.firmName}
                </a>
                <p className="nav-address" > {this.state.address && this.state.address.length > 70 ? this.state.address.substr(0, 70) + "..." : this.state.address}</p>
                <span className="tooltiptext">{this.state.address && this.state.address}</span>
              </div>
            </div>
          </div>
          <div className="collapse navbar-collapse">
            {/* <ul className="nav navbar-nav navbar-left">
            <li>
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-dashboard"></i>
                <p className="hidden-lg hidden-md">Dashboard</p>
              </a>
            </li>
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-globe"></i>
                <b className="caret hidden-lg hidden-md"></b>
                <p className="hidden-lg hidden-md">
                  5 Notifications
                  <b className="caret"></b>
                </p>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">Notification 1</a>
                </li>
                <li>
                  <a href="#">Notification 2</a>
                </li>
                <li>
                  <a href="#">Notification 3</a>
                </li>
                <li>
                  <a href="#">Notification 4</a>
                </li>
                <li>
                  <a href="#">Another notification</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="">
                <i className="fa fa-search"></i>
                <p className="hidden-lg hidden-md">Search</p>
              </a>
            </li>
          </ul> */}

            {/* <ul className="nav navbar-nav navbar-right">
            <li> <a onClick={props.themeUpdate}>{props.icon}</a> </li>
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <p>
                  Dropdown
                  <b className="caret"></b>
                </p>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">Action</a>
                </li>
                <li>
                  <a href="#">Another action</a>
                </li>
                <li>
                  <a href="#">Something</a>
                </li>
                <li>
                  <a href="#">Another action</a>
                </li>
                <li>
                  <a href="#">Something</a>
                </li>
                <li className="divider"></li>
                <li>
                  <a href="#">Separated link</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">
                <p>Log out</p>
              </a>
            </li>
            <li className="separator hidden-lg"></li>
          </ul> */}
          </div>
        </div>
      </nav>
    );
  }
};

export default Nav;
