import React, { Component } from 'react';
import Input from "../../../Components/UI/Input/Input";
import Button from "../../../Components/UI/Button/Neu_button";
import "./EditLayout.css"
import axios from 'axios';
import MessageBox from "../../../Components/BasicMessageBox/MessageBox";
import Spinner from "../../../Components/UI/Spinner/Spinner"
import * as actionTypes from "../../../store/actions/actionTypes"
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";

class EditLayout extends Component {

    token = localStorage.getItem('token');

    state = {
        Controls: {

            firmName: {
                theme: this.props.theme,
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Enter your Firm name",
                    id: "profile-firmName"
                },
                value: '',
                validation: {
                    required: true,

                },
                valid: false,
                touched: false
            },
            name: {
                theme: this.props.theme,
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Enter your name",
                    id: "profile-name"
                },
                value: '',
                validation: {
                    required: true,

                },
                valid: false,
                touched: false
            },
            address: {
                theme: this.props.theme,
                elementType: "textarea",
                elementConfig: {
                    type: "text",
                    placeholder: "Enter your address",
                    id: "profile-address",
                    autocomplete: "off"
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            password: {
                theme: this.props.theme,
                elementType: "input",
                elementConfig: {
                    type: "password",
                    placeholder: "Enter your current password",
                    id: "profile-password"
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6

                },
                valid: false,
                touched: false
            }
        },
        isVerified: false,
        showChangePassword: false,
        messageBox: { show: false, type: null, msg: null },
        companyLogo: ""
    };

    componentWillMount() {
        this.state.Controls.firmName.value = this.props.firmName;
        this.state.Controls.name.value = this.props.name;
        this.state.isVerified = this.props.phone.verified;
        this.state.Controls.address.value = this.props.address;
    }
    componentDidMount() {
        document.getElementById('profile-phone').value = this.props.phone.number;
        // this.setState({ Controls: { firmName: { value: this.props.elements.firmName } } });
        // this.setState({ Controls: { name: { value: this.props.elements.name } } });
        // this.setState({ isVerified: this.props.elements.phone.verified });
        // this.setState({ Controls: { address: { value: this.props.elements.address } } });
        this.setState({ companyLogo: this.props.profile_icon });

    }


    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.Controls,
            [controlName]: {
                ...this.state.Controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.Controls[controlName].validation),
                touched: true
            }
        };
        this.setState({ Controls: updatedControls });
    }

    addPhoneNumberHandler = () => {
        let password = document.getElementById('profile-password');
        let phone = document.getElementById('profile-phone');

        if (password.value && phone.value) {
            let addPhoneData = {
                phone: phone.value,
                phoneType: "update",
                password: password.value
            }

            if (this.props.phone.number === phone.value) {
                addPhoneData = {
                    phone: phone.value,
                    phoneType: "resend"
                }
            }
            else if (!this.props.phone.number) {
                addPhoneData = {
                    phone: phone.value,
                    phoneType: "new"
                }
            }
            const headers = {
                headers: {
                    'x-api-key': this.token,
                    'Content-Type': 'application/json'
                }
            };

            console.log(addPhoneData);
            axios.post(`${process.env.REACT_APP_BASE_URL}/users/add-phone`, addPhoneData, headers)
                .then(res => {
                    console.log(res);
                    document.getElementById('otpContainer').style.display = "inline-block";
                })
                .catch(err => {

                    console.log({ err });
                    if (err.response.data.error.includes('incorrect password'))
                        password.classList.add('Invalid');
                    if (err.response.data.error.includes('Phone number already exists')) {
                        document.getElementById('otpContainer').style.display = "inline-block";
                    }
                })
        }
        else {
            if (!phone.value)
                phone.classList.add('Invalid');
            else
                password.classList.add('Invalid');
        }
    }

    verifyPhoneNumberHandler = () => {
        let otp = document.getElementById('profile-otp');
        let phone = document.getElementById('profile-phone');

        if (otp.value && phone.value) {
            const addPhoneData = {
                phone: phone.value,
                otp: otp.value
            }
            const headers = {
                headers: {
                    'x-api-key': this.token,
                    'Content-Type': 'application/json'
                }
            };
            axios.post(`${process.env.REACT_APP_BASE_URL}/users/verify-phone`, addPhoneData, headers)
                .then(res => {
                    console.log(res);
                    otp.classList.remove('Invalid')
                    this.setState({ isVerified: true });
                })
                .catch(err => {
                    //  window.scrollBy(0, -1000);
                    console.log(err.response.data.error);
                    otp.classList.add('Invalid')
                })
        }
        else {
            if (!phone.value)
                phone.classList.add('Invalid');
            else
                otp.classList.add('Invalid');
        }
    }

    phoneChangeHandler = () => {
        let phone = document.getElementById('profile-phone');

        if (phone.value !== this.props.phone.number) {
            this.setState({ isVerified: false })
        }
        else {
            if (this.props.phone.verified)
                this.setState({ isVerified: true })
        }
    }

    showChangePassword = () => {
        this.setState({ showChangePassword: true })
    }

    confirmPassValidation = () => {
        let newPassword = document.getElementById('profile-newPassword');
        let confirmPassword = document.getElementById('profile-confirmPassword');
        let updateButton = document.getElementById('profile-changePassButton');
        let isValid = true;

        const con1 = isValid = newPassword.value.length > 5 && isValid;
        const con2 = isValid = newPassword.value === confirmPassword.value && isValid;

        if (!isValid) {
            if (!con1) {
                newPassword.classList.add('Invalid')
                updateButton.style.display = 'none';
            }
            else {
                newPassword.classList.remove('Invalid')
            }
            if (!con2) {
                confirmPassword.classList.add('Invalid');
                updateButton.style.display = 'none';
            }
            else {
                confirmPassword.classList.add('Invalid');
            }
        }
        else {
            newPassword.classList.remove('Invalid');
            confirmPassword.classList.remove('Invalid');
            updateButton.style.display = 'inline-block';
        }
    }

    changePasswordHandler = () => {

        let currentPassword = document.getElementById('profile-password');
        let newPassword = document.getElementById('profile-newPassword');


        const changePassData = {
            old_pass: currentPassword.value,
            new_pass: newPassword.value
        }
        const headers = {
            headers: {
                'x-api-key': this.token,
                'Content-Type': 'application/json'
            }
        };
        axios.post(`${process.env.REACT_APP_BASE_URL}/users/reset-password`, changePassData, headers)
            .then(res => {
                console.log(res);
                this.setState({ showChangePassword: false });
                currentPassword.classList.remove('Invalid')
                this.setState({ MessageBox: { show: true, type: 'success', msg: res.data.msg } })
            })
            .catch(err => {
                console.log(err.response.data.error);
                currentPassword.classList.add('Invalid')
                this.setState({ MessageBox: { show: true, type: 'error', msg: err.response.data.error } })
            })
    }

    uplaodCompanyLogo = async (event) => {
        let fileSize, requiredSize = 1000000;
        fileSize = event.target.files[0] &&
            (event.target.files[0].size);
        if (fileSize < requiredSize) {
            document.getElementById('logoError').innerHTML = ""
            const selectedFile = event.target.files[0];
            document.getElementById('profile-companyLogo').value = selectedFile.name;
            const fileData = await this.convertBase64(selectedFile);
            console.log(fileData);
            this.setState({ companyLogo: fileData });
        }
        else {
            document.getElementById('logoError').innerHTML = `Logo size should be less than ${Math.ceil(requiredSize / 1024)}KB.`
        }
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = () => {
                reject(fileReader.error);
            }
        });
    }

    render() {
        let formElementsArray = [];
        for (let key in this.state.Controls) {
            formElementsArray.push({ id: key, config: this.state.Controls[key] })
        }

        let form = formElementsArray.map(formElement => {
            return <Input key={formElement.id}
                theme={this.props.theme}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}

            />
        })

        const cssStyle = {
            button: {
                border: "none",
                backgroundColor: "#FFCD42",
                color: "white",
                outline: "none",
                padding: "5px 15px",
                borderRadius: "20px",
                display: "inline-block",
                margin: "10px",
                fontFamily: "century gothic",
                boxShadow: " 5px 5px 10px #FFCD4244"
            },
            buttonVerify: {
                backgroundColor: "#22bc96",
                boxShadow: "5px 5px 10px #22bc9644"
            },
            buttonUploadLogo: {
                backgroundColor: "#FE8D45",
                boxShadow: "5px 5px 10px #FE8D4544",
                marginLeft: "-100px"
            },
            icon: {
                color: "white"

            }
        }

        let buttonTitle = null, iconClass = null;

        // console.log(this.props.elements.phone);
        // console.log(this.phone)
        if (!this.props.phone.number) {
            buttonTitle = "Add"
            iconClass = "fal fa-plus-circle";
        }
        else if (this.phone !== this.props.phone.number) {
            buttonTitle = "Update";
            iconClass = "fal fa-sync";
        }
        else if (!this.props.phone.number && !this.state.isVerified) {
            buttonTitle = "Resend";
            iconClass = "fal fa-redo";
        }



        return (
            <>
                {this.state.messageBox.show && <MessageBox msg={this.state.messageBox.msg} type={this.state.state.messageBox.type} />}
                <div className="profile-EditLayout">

                    {form}
                    <div>
                        {!this.state.showChangePassword ? <p style={{ display: "inline-block", fontSize: "14px", fontWeight: "normal", padding: "10px 20px", margin: "0", textDecoration: "underline", cursor: "pointer" }} onClick={this.showChangePassword}>Change Password ?</p>
                            :
                            <>
                                <input theme={this.props.theme} class="ee_input" type="password" onChange={this.confirmPassValidation} style={{ display: "inline-block", boxSizing: "border-box", width: "40%" }} placeholder="Enter your new password" id="profile-newPassword" />
                                <input theme={this.props.theme} class="ee_input" type="password" onChange={this.confirmPassValidation} style={{ display: "inline-block", boxSizing: "border-box", width: "40%" }} placeholder="Confirm your new password" id="profile-confirmPassword" />
                                <button style={{ display: "none" }} onClick={this.changePasswordHandler} className="profile-changePassButton" id="profile-changePassButton"> <i style={cssStyle.icon} className="fal fa-sync"></i>  Update</button>
                            </>
                        }
                        <br />
                        <input theme={this.props.theme} class="ee_input" type="text" style={{ display: "inline-block", boxSizing: "border-box", width: "auto" }} onChange={this.phoneChangeHandler} placeholder="Enter your phone number" id="profile-phone" />
                        {
                            !this.state.isVerified ?
                                <>
                                    <button onClick={this.addPhoneNumberHandler} className="profile-phoneAdd" > <i style={cssStyle.icon} className={iconClass}></i>  {buttonTitle}</button>
                                    <div style={{ display: "none" }} className="animated flipInX" id="otpContainer" >
                                        <input theme={this.props.theme} class="ee_input" type="text" style={{ display: "inline-block", boxSizing: "border-box", width: "auto" }} placeholder="Enter OTP" id="profile-otp" />
                                        <button className="profile-phoneVerify" onClick={this.verifyPhoneNumberHandler}><i style={cssStyle.icon} className="fal fa-badge-check"></i> Verify </button>
                                    </div>
                                </>
                                :
                                <span className="animated flipInX"><i style={{ fontSize: "20px", color: "#22bc96", marginLeft: "-40px" }} className="fad fa-badge-check"></i></span>
                        }
                        <br />
                        <input theme={this.props.theme} class="ee_input" type="text" style={{ display: "inline-block", boxSizing: "border-box", width: "70%" }} placeholder="Insert your Company Logo" id="profile-companyLogo" />
                        <div className="browseImageWrapper" style={{ display: "inline-block", boxSizing: "" }}>
                            <input style={{ opacity: "0", width: "0" }} id="logoDataUrl" value={this.state.companyLogo} />
                            <input type="file" accept="image/*" style={{ display: "inline-block", opacity: "0", width: "100px" }} id="profile-BrowseCompanyLogo" onChange={(e) => { this.uplaodCompanyLogo(e) }} />
                            <button className="profile-addProfilePic" for="profile-BrowseCompanyLogo    "> <i style={cssStyle.icon} className="fad fa-file-alt"></i>{" "} Browse</button><br />
                        </div>
                        <br />
                        <p id="logoError" style={{ color: "crimson", fontSize: "12px", fontFamily: "century gothic", marginLeft: "40px" }}></p>
                    </div>
                    {/* <button className="circular-button" onClick={this.props.edit}><i className="fad fa-save"></i></button> */}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.profile.name, email: state.profile.email, phone: state.profile.phone, firmName: state.profile.firm_name, address: state.profile.address, geo_coordinates: state.profile.geo_coordinates, profile_icon: state.profile.profile_icon, error: state.profile.error, loading: state.profile.loading,
    }
}

export default connect(mapStateToProps, null)(EditLayout);