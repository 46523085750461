import React, { Component } from 'react';
import LineChart from "../../LineChart/LineChart";
import DynamicCards from "../../DynamicCards/DynamicCards";
import Card from "../../Card/Card";
import Forcaste from "../../ForcasteCard/ForcasteCard";
// import Backup from "../../BackupCard/BackupCard";
import Maps from "../../Maps/Maps";

// import Windrose from "../../WindRose/WindRose";

class WMS extends Component {

    render() {
        return (
            <div>
                <div className="row rowGap">
                    <div className="col-md-9">
                        <DynamicCards token={this.props.token} selectedId={this.props.selectedId} />
                    </div>
                    <div className="col-md-3">
                        <Card themecolor="blue">
                            <Forcaste isavailable="true" selectedId={this.props.selectedId} />
                        </Card>
                    </div>
                </div>
                <div className="row rowGap">
                    {/* <div className="col-md-4">
                        <Card themecolor="green">
                            <Backup token={this.props.token} deviceId={this.props.selectedId} />
                        </Card>
                    </div> */}
                    {/* <div className="col-md-5">
                        <Windrose />
                    </div> */}
                    <div className="col-md-12">
                        <Card themecolor={this.props.dataColor} style={{ paddingBottom: "0" }}>
                            <LineChart token={this.props.token} selectedId={this.props.selectedId} dataColor={this.props.dataColor} />
                        </Card>
                    </div>
                </div>
                <div className="row rowGap">
                    <div className="col-md-12" >
                        <Card
                            themecolor={this.props.dataColor}
                            textColor={this.props.text}
                            style={{ height: "400px" }}
                            isavailable="false"
                        >
                            <Maps location={this.props.deviceLocation} dataColor={this.props.dataColor} />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default WMS;