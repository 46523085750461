import * as actionTypes from "./actionTypes";
import axios from "axios";

export const reportStart = () => {
    return { type: actionTypes.REPORT_START }
}

export const reportSuccess = (reportData, method, fileType) => {
    return { type: actionTypes.REPORT_SUCCESS, reportData: reportData, method: method, fileType: fileType }
}

export const reportFlush = () => {
    return { type: actionTypes.REPORT_FLUSH }
}

export const reportError = (error) => {
    return { type: actionTypes.REPORT_FAIL, error: error }
}

export const generateReport = (id, from, to, freq, method, fileType) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        dispatch(reportStart());
        console.log(`${process.env.REACT_APP_BASE_URL}/devices/getDataByDate/${id}/${from}/${to}/${freq}`);
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/devices/getDataByDate/${id}/${from}/${to}/${freq}`, {
                headers: {
                    'x-api-key': token
                }
            })
            .then(res => {
                console.log({ res })
                dispatch(reportSuccess(res.data, method, fileType));
                setTimeout(function () { if (method === actionTypes.REPORT_DOWNLOAD) dispatch(reportFlush()) }, 2000);

            }).catch(error => {
                console.log({ error })
                error.response && dispatch(reportError(error.response.data));
                error.message && dispatch(reportError(error.message));
                dispatch(reportFlush());
            })
    }
}
