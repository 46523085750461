import React from 'react';
import "./Input.scss";
// import classes from './Input.scss';

const Input = (props) => {
    let inputElement = null;
    const inputClasses = ["ee_input"];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push("Invalid");
    }

    switch (props.inputtype) {
        case 'input': inputElement = <input className={inputClasses.join(' ')} theme={props.theme}  {...props.elementConfig}
            value={props.value}
            onChange={props.changed} />; break;
        case 'textarea': inputElement = <textarea className={inputClasses.join(' ')} theme={props.theme}  {...props.elementConfig}
            value={props.value}
            onChange={props.changed} />; break;
        case 'select': <select
            className={inputClasses.join(' ')}
            theme={props.theme}
            value={props.value}
            onChange={props.changed}>
            {props.elementConfig.options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.displayValue}
                </option>
            ))}
        </select>; break
        default: inputElement = <input className={inputClasses.join(' ')} theme={props.theme} {...props.elementConfig}
            value={props.value}
            onChange={props.changed} />; break;
    }

    return (
        <div>
            {/* <label>{props.lable}</label> */}
            {inputElement}
        </div>
    )
}

export default Input;