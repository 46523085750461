import React, { Component } from "react";
import "./Customise.scss";

class customiseCard extends Component {
  render() {
    const _icon = () => {
      let str = this.props.dataIcon;
      let htmlObject = document.createElement("div");
      htmlObject.innerHTML = str;
      return htmlObject;
    };

    return (
      <div
        className="demo-card-wide _card mdl-card mdl-shadow--2dp"
        data-color={this.props.themename}
      >
        {/* {console.log(_icon())} */}
        <h1
          className="cus_heading --ee-theme text-center"
          head-color={this.props.textColor}
          themeColor={this.props.dataColor}
        >
          {this.props.header}
        </h1>
        {this.props.children}
      </div>
    );
  }
}

export default customiseCard;
