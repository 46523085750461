import React, { Component } from "react";
import Cookies from "universal-cookie";
import SubNavBar from "../../Components/SubNavBar/SubNavBar.js";

import axios from "axios";
import WMS from "../../Components/Layouts/CardLayout/WMS";
import IAQ from "../../Components/Layouts/CardLayout/IAQ";
import Default from "../../Components/Layouts/CardLayout/Default"
import NotAdded from "../../Components/Layouts/NotAdded/NotAdded"

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";


class mainPage extends Component {

  // constructor(props) {
  //   super(props);
  //   console.log(props)
  //   if (window.performance) {
  //     // this.count++;
  //     if (performance.navigation.type == 1) {
  //       // count++;
  //       alert(`This page is reloaded`);
  //     } else {
  //       alert("This page is not reloaded");
  //     }
  //   }
  // }

  state = {
    lut: "",
    selectedId: "nan",
    ids: []
  };

  isMountedState;

  _promise = false;
  cookies = new Cookies();
  firstTime = false;
  ids = [];
  alias = [];
  _chartRotation = 0;


  token = localStorage.getItem('token');

  checkLutInterval;


  componentDidMount() {


    const isMobileView = window.matchMedia("(max-width: 768px)").matches;

    if (isMobileView) {
      // Code for mobile view
      document.getElementById("sidebar").style = "width:0"
    }

    console.log(this.userDetails)
    this.isMountedState = true;

    const devices = JSON.parse(localStorage.getItem('devices'));
    console.log(this.cookies.get('selectedId'));


    const lut = () => {
      console.log(devices.devices[0]);
      console.log(this.state.selectedId)
      let defaultId = JSON.parse(localStorage.getItem('devices')).devices.filter(device => device.serviceDetails.status === "active")[0]?.deviceDetails.device_id;
      let id = this.cookies.get('selectedId') || defaultId;
      // alert(id)
      if (id) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/devices/getLatestDeviceData/${id}`, {
            headers: {
              'x-api-key': this.token
            }
          })
          .then((res) => {
            if (this.isMountedState) {
              console.log(res);
              this.setState({ lut: res.data.recieved_time })
              // this.setState({ lut: res.data[res.data.length - 1] });
            }
          });
      }
    }

    lut();

    if (devices.devices[0]) {
      // this.checkLutInterval = setInterval(lut, 5000);

      this.props.checkAuthStatus();
      setInterval(() => { this.props.checkAuthStatus() }, 10000);


      console.log(devices);

      this.ids = devices.devices.filter(item => item.serviceDetails.status === 'active').map(item => { return { "alias": item.deviceDetails.alias, "id": item.deviceDetails.device_id } });
      console.log(this.ids);
      // this.alias = devices.map(item => item.alias);

      this.setState({ ids: this.ids });
      this.setState({ alias: this.alias });

      if (this.cookies.get('selectedId') === "undefined" || this.cookies.get('selectedId') === undefined) {
        this.cookies.set("selectedId", this.ids[0].id, { path: '/' });
        this.setState({ selectedId: this.ids[0].id }, () => {
          console.log(this.state.selectedId + " " + this.ids[0].id)
          document.getElementById("deviceIdSelect").value = this.ids[0].id;
        });
      }
      else if (devices.length < 1) {
        this.cookies.set("selectedId", "undefined", { path: '/' });
        this.setState({ selectedId: "undefined" }, () => {
          console.log(this.state.selectedId + " " + this.ids[0])
          // document.getElementById("deviceIdSelect").value = this.ids[0];
        });
      }
      else
        this.setState({ selectedId: this.cookies.get('selectedId') }, () => {
          try {
            document.getElementById("deviceIdSelect").value = this.state.selectedId;
          }
          catch (e) {
            console.log(e)
          }
        });
    }
  }   // end of Component Did Mount

  componentWillUnmount() {
    this.isMountedState = false;
  }

  // shouldComponentUpdate(nextProps) {
  //   return (this.props !== nextProps);
  // }


  deviceIdSelect = () => {
    let _id = document.getElementById("deviceIdSelect");
    this.setState({ selectedId: _id.value }, () => { this.cookies.set("selectedId", this.state.selectedId, { path: "/" }); });
    window.location.reload();
  };
  foreCount = 1;
  _para_change = 0;

  getLutHandler = (lut) => {
    this.setState({ lut });
  }

  render() {
    // console.log(this.cookies.get("selectedId"))
    // console.log(this.state.selectedId)
    const devices = JSON.parse(localStorage.getItem('devices'))

    const userDetails = devices.userDetails;

    let deviceslocation = null;
    if (devices.devices) {
      console.log(devices.devices)
      deviceslocation = devices.devices.map((item) => {
        return { [item.deviceDetails.device_id]: item.deviceDetails.location };
      })
      console.log(deviceslocation)
    }

    let selectedLocation = null;
    for (let item of deviceslocation) {
      let element = Object.keys(item)[0];
      console.log(element)
      if (element === this.state.selectedId) {
        selectedLocation = item[element];
        console.log(item[element]);
        break;
      }
    }
    console.log(selectedLocation)


    let finalLayout = null;
    // alert(this.state.selectedId.substr(0, 3))
    let deviceType = null;

    if (this.state.selectedId) {
      deviceType = this.state.selectedId.substr(0, 3);
    }


    switch (deviceType) {
      case 'WMS': finalLayout = <WMS selectedId={this.state.selectedId} deviceLocation={selectedLocation} setLut={this.getLutHandler} token={this.token} dataColor={this.props.dataColor} />; break
      case 'IAQ': finalLayout = <IAQ selectedId={this.state.selectedId} deviceLocation={selectedLocation} token={this.token} dataColor={this.props.dataColor} />; break
      default: { finalLayout = <Default selectedId={this.state.selectedId} deviceLocation={selectedLocation} token={this.token} dataColor={this.props.dataColor} /> }
    }

    if ((deviceType) === "nan") {
      // alert(deviceType)
      finalLayout = <NotAdded />
    }

    console.log(this.state.selectedId)
    return (
      <div className="content">
        {/* { !isNaN(deviceType) && */}
        <SubNavBar compName={userDetails.name.toUpperCase()} lut={this.state.lut !== null && this.state.lut} onChange={this.deviceIdSelect} selectedId={this.state.selectedId} devDetail={[]} ids={this.state.ids} alias={this.state.alias} />
        {/* } */}
        {finalLayout}
      </div>
    );
  }
}



const mapDispatchToProps = dispatch => {
  return { checkAuthStatus: () => dispatch(actions.authCheckState()) }
}


export default connect(null, mapDispatchToProps)(mainPage);