import * as actionTypes from "./actionTypes";
import axios from "axios";


export const getInfoStart = () => {
    return { type: actionTypes.GET_INFO_START }
}

export const getInfoSuccess = (name, email, phone, firm_name, address, geo_coordinates, profile_icon) => {
    return { type: actionTypes.GET_INFO_SUCCESS, name: name, email: email, phone: phone, firm_name: firm_name, address: address, geo_coordinates: geo_coordinates, profile_icon: profile_icon }
}

export const getInfoError = (error) => {
    return { type: actionTypes.GET_INFO_FAIL, error: error }
}

export const setInfoSuccess = (name, email, phone, firm_name, address, geo_coordinates, profile_icon) => {
    return { type: actionTypes.SET_INFO_SUCCESS, name: name, email: email, phone: phone, firm_name: firm_name, address: address, geo_coordinates: geo_coordinates, profile_icon: profile_icon }
}

export const getUserInfo = () => {
    const token = localStorage.getItem('token');

    return dispatch => {
        console.log("########## GET USER INFO ##############");
        console.log(token)
        dispatch(getInfoStart());

        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/users/get-profile`, {
                headers: {
                    'x-api-key': token
                }
            }).then(res => {
                // localStorage.setItem('firmName', res.data.firm_name);
                // localStorage.setItem('address', res.data.address);
                // localStorage.setItem('name', res.data.name);
                let CurrObj = JSON.parse(localStorage.getItem('devices'));
                CurrObj.userDetails.name = res.data.name;
                CurrObj.userDetails.address = res.data.address;
                CurrObj.userDetails.firm_name = res.data.firm_name;

                localStorage.setItem('devices', JSON.stringify(CurrObj));
                dispatch(getInfoSuccess(res.data.name, res.data.email, res.data.phone, res.data.firm_name, res.data.address, res.data.geo_coordinates, res.data.profile_icon))
            }).catch(error => {
                if (error.response)
                    dispatch(getInfoError(error.response.data.error))
                else {
                    dispatch(getInfoError(error.message))
                }
            })
    }

}

export const setUserInfo = (password, firm_name, name, address, geo_coordinates, profile_icon) => {

    const token = localStorage.getItem('token');

    return dispatch => {
        console.log(token)
        dispatch(getInfoStart());
        const editData = {
            password: password,
            firm_name: firm_name,
            name: name,
            address: address,
            profile_icon: profile_icon,
            geo_coordinates: [20, 50]
        }
        console.log(editData)
        const header = {
            headers: {
                'x-api-key': this.token,
                'Content-Type': 'application/json'
            }
        };

        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/users/edit-profile`, editData, header).then(res => {
                    dispatch(setInfoSuccess(res.data.name, res.data.email, res.data.phone, res.data.firm_name, res.data.address, res.data.geo_coordinates, res.data.profile_icon))
                }).catch(error => {
                    if (error.response)
                        dispatch(getInfoError(error.response.data.error))
                    else {
                        dispatch(getInfoError(error.message))
                    }
                })
    }
}
