import React, { Component } from 'react';

class MessageBox extends Component {
    render() {

        let cssClass = null, cssStyle = null;

        if (this.props.type === 'success') {
            cssClass = "mbSuccessMsg animated flipInX";
        }
        if (this.props.type === 'error') {
            cssClass = "mbErrorMsg animated flipInX";
        }

        setTimeout(() => { cssStyle = { display: "none" } }, 2000);

        return (
            <>
                <p className={cssClass} style={cssStyle}>{this.props.successMsg}</p>
            </>
        );
    }
}

export default MessageBox;