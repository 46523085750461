import React, { Component } from 'react';
import Spinner from "../../../Components/UI/Spinner/Spinner"
import * as actionTypes from "../../../store/actions/actionTypes"
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";



class ShowLayout extends Component {

    userDetails = JSON.parse(localStorage.getItem('devices')).userDetails;


    render() {
        // console.log(this.props.phone.number)
        // alert(this.userDetails.email)
        let phoneNumber, phoneVerified;
        try {
            phoneNumber = this.props.phone.number;
            phoneVerified = this.props.phone.verified;
        }
        catch (e) {

        }
        return (
            <>
                {this.props.firmName && <h5 className="profile-firm-name">{this.props.firmName}</h5>}
                {this.props.name && <span> <i className="fad fa-user"></i> <label className="profile-user-name">{this.props.name.toUpperCase()}</label> </span>}
                {this.props.email && <span> <i className="fad fa-envelope"></i> <label className="profile-user-mail">{this.props.email}</label> </span>}
                {phoneNumber && <span> <i className="fad fa-phone"></i> <label className="profile-user-phone">{phoneNumber}</label>{"     "}{phoneVerified && <i style={{ color: '#22bc96', fontSize: "1.2rem" }} className="fad fa-badge-check"></i>}</span>}
                {this.props.address && <span> <i className="fad fa-home"></i> <label className="profile-user-address">{this.props.address}</label> </span>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.profile.name, email: state.profile.email, phone: state.profile.phone, firmName: state.profile.firm_name, address: state.profile.address, geo_coordinates: state.profile.geo_coordinates, profile_icon: state.profile.profile_icon, error: state.profile.error, loading: state.profile.loading,
    }
}


export default connect(mapStateToProps, null)(ShowLayout);