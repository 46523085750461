import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility"

const initialState = {
    reportData: null, error: null, loading: false, method: null, fileType: null
};

const reportStart = (state) => {
    return updateObject(state, { error: null, loading: true })
}
const reportSuccess = (state, action) => {
    return updateObject(state, { name: action.name, reportData: action.reportData, method: action.method, fileType: action.fileType, error: null, loading: false })
}
const reportFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REPORT_START: return reportStart(state)
        case actionTypes.REPORT_SUCCESS: return reportSuccess(state, action)
        case actionTypes.REPORT_FAIL: return reportFail(state, action)
        default: return state;
    }
}

export default reducer;