import * as actionTypes from "./actionTypes"
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let logoutTime = 1 * 60 * 60 * 1000;        // 1 hr

export const authStart = () => {
    return { type: actionTypes.AUTH_START }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('devices');
    localStorage.removeItem('firmName');
    cookies.remove('selectedId');
    return { type: actionTypes.AUTH_LOGOUT }
}

export const authSuccess = (token, devices, msg) => {
    return { type: actionTypes.AUTH_SUCCESS, idToken: token, devices: devices, message: msg }
}
export const checkAuthTimeout = (timeout = logoutTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
            window.location.reload();
        }, timeout)
    }
}

export const authFail = (error) => {
    return { type: actionTypes.AUTH_FAIL, error: error }
}

export const auth = (name, email, password, method) => {
    return dispatch => {
        dispatch(authStart());
        let authData = {}, url = "";

        switch (method) {
            case actionTypes.METHOD_SIGNIN: {
                authData = {
                    email: email,
                    password: password
                }
                url = `${process.env.REACT_APP_BASE_URL}/users/login`;
                break;
            }
            case actionTypes.METHOD_SIGNUP: {
                url = `${process.env.REACT_APP_BASE_URL}/users/signup`;
                authData = {
                    email: email,
                    password: password,
                    name: name
                }
                break;
            }
            case actionTypes.METHOD_FORGET_PASS: {
                url = `${process.env.REACT_APP_BASE_URL}/users/forgot-password-link`;
                authData = {
                    email: email
                }
                break;
            }
        }

        console.log({ authData, url })
        axios.post(url, authData)
            .then(response => {
                console.log(response.data);
                const expirationTime = new Date(new Date().getTime() + logoutTime)
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('expirationTime', expirationTime);
                    localStorage.setItem('devices', JSON.stringify(response.data));
                }
                dispatch(authSuccess(response.data.token, response.data, response.data.msg));
                dispatch(checkAuthTimeout());
            })
            .catch(error => {
                try {
                    console.log({ error });
                    error.message && dispatch(authFail(error.message));
                    error.response.data.error && dispatch(authFail(error.response.data.error));
                }
                catch (e) {
                    console.log(e)
                }
            })
    };
}

export const authCheckState = () => {
    return dispatch => {

        const token = localStorage.getItem('token');
        console.log('authCheckState')
        if (!token) {
            dispatch(logout());
            window.location.reload();
            document.location.href = "/";
            // alert(1);
        }
        else {
            const expirationTime = new Date(localStorage.getItem('expirationTime'));
            if (expirationTime < new Date()) {
                // alert(2);
                dispatch(logout());
                window.location.reload();
                document.location.href = "/";
            }
            else {
                // alert(3);
                const devices = JSON.parse(localStorage.getItem('devices'));
                dispatch(authSuccess(token, devices));
                dispatch(checkAuthTimeout())
            }
        }
    }
}