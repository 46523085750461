import React from "react";
import "./MessageBox.scss";
// import icon from "../img/undraw_cancel_u1it.svg";
import info_icon from "../img/undraw_instant_information_6755 (2).svg";
import warning_icon from "../img/undraw_notify_88a4.svg";
import success_icon from "../img/undraw_confirmation_2uy0 (1).svg";

// import "..srcassetscssanimate.css";

import { getElement } from "@amcharts/amcharts4/core";

const messageBox = props => {
  var alertType = {
    info: {
      color: "blue",
      icon: info_icon
    },
    warning: {
      color: "red",
      icon: warning_icon
    },
    success: {
      color: "green",
      icon: success_icon
    }
  };

  // const close = () => {
  //   alert(1);
  //   // document.getElementsByClassName("msg_container").style.display = "none";
  //   // props.isavailable = "false";
  //   style = { display: "none" };
  // };

  return (
    <div
      className={props._class}
      id="msg_container"
      // isavailable={props.available}
      themeColor={alertType[props._type].color}
    >
      {/* <div className="closeBtn" id="closebtn" onClick={close}>
        X
      </div> */}
      <div className="row>">
        <div className="col-md-10">
          <div className="msg_header">{props.headerText}</div>
          <hr></hr>
          <div className="msg_body">{props.bodyText}</div>
        </div>
        <div className="col-md-2">
          <img
            className="msg_icon"
            src={alertType[props._type].icon}
            alt="React Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default messageBox;
