export function extractAlphabets(inputString) {
    // Use a regular expression to match only alphabets
    const alphabetsOnly = inputString.replace(/[^a-zA-Z]/g, "");
    return alphabetsOnly;
}

export function isDateOlderThanThreeDays(dateToCheck) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the provided date
    const timeDifference = currentDate - dateToCheck;

    // console.log(timeDifference);

    // Calculate the number of milliseconds in 3 days
    const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000;
    return timeDifference > threeDaysInMillis;
}