import React, { Component } from 'react';
import Input from "../../Components/UI/Input/Input"
import "./Auth.scss";
import wave from "../../assets/img/wave4.svg";
import waveMob from "../../assets/img/wave4 (3).svg";
import side_img from "../../assets/img/undraw_podcast_q6p7.svg";
import profile from "../../assets/img/EE-LOGO-white_only1.png";
import Button from "../../Components/UI/Button/Neu_button"
import Spinner from "../../Components/UI/Spinner2/Spinner2"
import * as actionTypes from "../../store/actions/actionTypes"
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

class Auth extends Component {
    state = {
        signinControls: {
            email: {
                elementType: "input",
                elementConfig: {
                    type: "email",
                    placeholder: "Enter your email"
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: "input",
                elementConfig: {
                    type: "password",
                    placeholder: "Enter your password"
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            }

        },
        signupControls: {
            name: {
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Enter your Name"
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: "input",
                elementConfig: {
                    type: "email",
                    placeholder: "Enter your email"
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: "input",
                elementConfig: {
                    type: "password",
                    placeholder: "Enter your password"
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            confirmPassword: {
                elementType: "input",
                elementConfig: {
                    type: "password",
                    placeholder: "Confirm your password"
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                    isMatched: true
                },
                valid: false,
                touched: false
            }
        },
        forgetPassControls: {
            email: {
                elementType: "input",
                elementConfig: {
                    type: "email",
                    placeholder: "Enter your email"
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }
        },
        method: actionTypes.METHOD_SIGNIN,
        showError: true,
        showSuccess: true,
        isValid: false,
        validationMsg: ""
    }

    componentDidMount = () => {

        let theme = localStorage.getItem('theme-data');

        if (theme === "Light") {
            document.documentElement.setAttribute("theme-data", "Light");
        }
        else {
            document.documentElement.setAttribute("theme-data", "Dark");
        }
    }

    checkValidity(cPass, value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
            this.setState({ validationMsg: "This field is required" });
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
            this.setState({ validationMsg: "Should contain atleast 6 characters" });
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid

        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
            this.setState({ validationMsg: "Email is not valid" });
        }

        if (rules.isMatched) {
            isValid = value === cPass && isValid
            this.setState({ validationMsg: "Confirm Password is not same as Password" });

        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
            this.setState({ validationMsg: "One Numeric Character required" });
        }
        this.setState({ isValid: isValid });
        isValid && this.setState({ validationMsg: "" })
        return isValid;
    }

    inputChangedHandler = (event, controlName) => {

        switch (this.state.method) {
            case actionTypes.METHOD_SIGNUP: {
                const updatedControls = {
                    ...this.state.signupControls,
                    [controlName]: {
                        ...this.state.signupControls[controlName],
                        value: event.target.value,
                        valid: this.checkValidity(this.state.signupControls.password.value, event.target.value, this.state.signupControls[controlName].validation),
                        touched: true
                    }
                };
                this.setState({ signupControls: updatedControls });
            }
                break;
            case actionTypes.METHOD_SIGNIN: {
                const updatedControls = {
                    ...this.state.signinControls,
                    [controlName]: {
                        ...this.state.signinControls[controlName],
                        value: event.target.value,
                        valid: this.checkValidity(null, event.target.value, this.state.signinControls[controlName].validation),
                        touched: true
                    }
                };
                this.setState({ signinControls: updatedControls });
            }
                break;
            case actionTypes.METHOD_FORGET_PASS: {
                const updatedControls = {
                    ...this.state.forgetPassControls,
                    [controlName]: {
                        ...this.state.forgetPassControls[controlName],
                        value: event.target.value,
                        valid: this.checkValidity(null, event.target.value, this.state.forgetPassControls[controlName].validation),
                        touched: true
                    }
                };
                this.setState({ forgetPassControls: updatedControls });
            }
                break;
            default: {

            }
        }
    }

    submitHandler = (event) => {
        this.setState({ showError: true });
        this.setState({ showSuccess: true });

        switch (this.state.method) {
            case actionTypes.METHOD_SIGNUP: {
                event.preventDefault();
                this.props.onAuth(this.state.signupControls.name.value, this.state.signupControls.email.value, this.state.signupControls.password.value, this.state.method);
            }
                break;
            case actionTypes.METHOD_SIGNIN: {
                event.preventDefault();
                this.props.onAuth(null, this.state.signinControls.email.value, this.state.signinControls.password.value, this.state.method);
            }
                break;
            case actionTypes.METHOD_FORGET_PASS: {
                event.preventDefault();
                this.props.onAuth(null, this.state.forgetPassControls.email.value, null, this.state.method);
            }
                break;
            default: {

            }
        }
    }

    switchAuthModeHandler = () => {
        this.setState(prevState => {
            if (prevState.method === actionTypes.METHOD_SIGNUP)
                return { method: actionTypes.METHOD_SIGNIN };
            else if (prevState.method === actionTypes.METHOD_SIGNIN)
                return { method: actionTypes.METHOD_SIGNUP };
        });
    }

    switchToForgetPass = () => {
        this.setState(prevState => {
            if (prevState.method === actionTypes.METHOD_SIGNIN)
                return { method: actionTypes.METHOD_FORGET_PASS };
            else if (prevState.method === actionTypes.METHOD_FORGET_PASS)
                return { method: actionTypes.METHOD_SIGNIN };
        });
    }


    switchIfSuccess = () => {
        if (this.props.message && this.props.message.includes('Sent!')) {
            this.setState({ method: actionTypes.METHOD_SIGNIN })
        }
    }
    switchIfSuccess;



    render() {
        // console.log(process.env.REACT_APP_LOCAL_URL);
        // console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

        const formElementsArray = [];

        let icon = this.state.method === actionTypes.METHOD_SIGNUP ? "fad fa-user-plus" : "fad fa-sign-in";
        let buttonTitle = this.state.method === actionTypes.METHOD_SIGNUP ? "Sign Up" : "Log In";

        switch (this.state.method) {
            case actionTypes.METHOD_SIGNUP: {
                for (let key in this.state.signupControls) {
                    formElementsArray.push({ id: key, config: this.state.signupControls[key] })
                }
                icon = "fad fa-user-plus";
                buttonTitle = "Sign Up";
            }
                break;
            case actionTypes.METHOD_SIGNIN: {
                for (let key in this.state.signinControls) {
                    formElementsArray.push({ id: key, config: this.state.signinControls[key] })
                }
                icon = "fad fa-sign-in";
                buttonTitle = "Log In";
            }
                break;
            case actionTypes.METHOD_FORGET_PASS: {
                for (let key in this.state.forgetPassControls) {
                    formElementsArray.push({ id: key, config: this.state.forgetPassControls[key] })
                }
                icon = "fad fa-badge-check";
                buttonTitle = "Verify";
            }
                break;
        }



        let form = formElementsArray.map(formElement => {
            return <Input key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}
            />
        })

        let errorMessage = null;
        let successMessage = null;
        // this.state.showError = this.props.error;
        if (this.props.loading) {
            form = <Spinner />
        }
        if (this.props.error && this.state.showError) {
            errorMessage = (<p className="errorMessage animated fadeInDown flipInX">{this.props.error}</p>);
            setTimeout(() => { this.setState({ showError: false }); }, 2500)
        }
        if (this.props.message && this.state.showSuccess) {
            // alert(this.props.message)
            successMessage = (<p className="successMessage animated fadeInDown flipInX">{this.props.message}</p>);
            setTimeout(() => { this.setState({ showSuccess: false }); }, 2500)
        }

        const linkStyle = { cursor: "pointer", fontSize: "11px", color: "#21BC97", textDecoration: "underline" }



        return (
            <div>
                {errorMessage}
                {successMessage}
                <img className="Wavebg animated fadeInUp " src={wave} />
                <img className="WaveMob animated fadeInUp" src={waveMob} />
                <div className="main-container">
                    <div className="side-image animated fadeIn">
                        <img src={side_img} />
                    </div>
                    <div className="login-container">
                        <div className="form">
                            <img className="p-image animated flipInY" src={profile} />
                            <h4>
                                Welcome to{" "}
                                <span style={{ fontSize: "26px", fontWeight: "bold" }}>Thingzcloud</span>
                            </h4>
                            <br></br>
                            <form onSubmit={this.submitHandler} >
                                {form}
                                <label className="validationErrorMsg" id="validationError">{this.state.validationMsg}</label>
                                <br />
                                <Button isDisabled={this.state.isValid} icon={icon} _style="main">{buttonTitle}</Button>
                            </form>
                            <br />
                            {(this.state.method === actionTypes.METHOD_SIGNIN || this.state.method === actionTypes.METHOD_SIGNUP) ? <p onClick={this.switchAuthModeHandler} style={linkStyle} >Do you want to {this.state.method === actionTypes.METHOD_SIGNIN ? "Signup" : "Signin"} Instead?</p> : null}
                            {(this.state.method === actionTypes.METHOD_SIGNIN || this.state.method === actionTypes.METHOD_FORGET_PASS) ? <p onClick={this.switchToForgetPass} style={linkStyle}> {this.state.method === actionTypes.METHOD_SIGNIN ? "Forget Password?" : <span><i class="fal fa-arrow-left"></i> {"  "} Back to Signin</span>} </p> : null}
                        </div>
                    </div>
                </div>
                <div className="bgCir"></div>
                <div className="bgCir2"></div>
                <div className="bgCir3"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        message: state.auth.message
    }
}

const mapDispatchToProps = dispatch => {
    return { onAuth: (name, email, password, method) => dispatch(actions.auth(name, email, password, method)) }
}


export default connect(mapStateToProps, mapDispatchToProps)(Auth);