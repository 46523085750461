import React, { Component } from 'react'
import DataBackup from "../../Components/BackupCard/BackupCard"
import { connect } from "react-redux";
import Table from "../../Components/Table/Table"
import "./report.css"
import Spinner from "../../Components/UI/Spinner2/Spinner2"
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as actionTypes from "../../store/actions/actionTypes"
import { extractAlphabets } from '../../utils';

class Reports extends Component {


    componentDidMount() {
        const isMobileView = window.matchMedia("(max-width: 768px)").matches;

        if (isMobileView) {
            // Code for mobile view
            document.getElementById("sidebar").style = "width:0"
        }
    }

    downloadData = () => {

        let prevParaAsPerType =
            JSON.parse(localStorage.getItem("visibleParaForType")) || {};

        console.log(prevParaAsPerType);
        const doc = new jsPDF();
        let _id = document.getElementById("deviceIdSelect__reports");
        console.log({ _id })
        let type = extractAlphabets(_id.value)

        try {
            let data = [];
            data = this.props.reportData;
            let data_length = 0;

            data_length = data[Object.keys(data)[0]].length;

            var csv = "",
                csv_header = "";
            var pdf_header = [],
                pdf_data = [],
                data_col = [];

            for (let l in data) {
                // only allowed parameters will be written in reports
                if (prevParaAsPerType[type]) {
                    if (prevParaAsPerType[type]?.includes(
                        l.split(" ").join("_").toLowerCase()
                    ) ||
                        l === "recorded_time" ||
                        l === "recieved_time") {
                        csv_header += l.toUpperCase().replace('_', " ") + ",";
                        pdf_header.push(l.toUpperCase().replace('_', " "))
                    }
                }
                else {
                    csv_header += l.toUpperCase().replace('_', " ") + ",";
                    pdf_header.push(l.toUpperCase().replace('_', " "))
                }
            }

            console.log({ csv_header })
            csv_header += "\n";

            for (let i = 0; i < data_length; i++) {
                for (let item in data) {
                    console.log({ item })

                    if (prevParaAsPerType[type]) {
                        if (prevParaAsPerType[type]?.includes(
                            item.split(" ").join("_").toLowerCase()
                        ) ||
                            item === "recorded_time" ||
                            item === "recieved_time") {
                            if (String(data[item][i]).includes(',')) {
                                data[item][i] = String(data[item][i]).replace(',', ' ')
                            }
                            csv += data[item][i] + ",";
                            data_col.push(data[item][i]);
                        }
                    }
                    else {

                        if (String(data[item][i]).includes(',')) {
                            data[item][i] = String(data[item][i]).replace(',', ' ')
                        }
                        csv += data[item][i] + ",";
                        data_col.push(data[item][i]);
                    }
                }

                pdf_data.push(data_col);
                data_col = [];
                csv += "\n";
            }

            csv = csv_header.concat(csv);
            if (data_length === 0) {
                this.showMessage("Sorry! No data recorded in between these dates");
            } else {

                //  alert(this.props.fileType);
                if (this.props.fileType === "csv") {
                    var hiddenElement = document.createElement("a");
                    hiddenElement.href =
                        "data:text/csv;charset=utf-8," + encodeURI(csv);
                    hiddenElement.target = "_blank";
                    hiddenElement.download =
                        `${_id.value}_.csv`;
                    hiddenElement.click();
                } else if (this.props.fileType === "pdf") {
                    doc.deletePage(1);
                    doc.addPage("a4", "landscape");
                    doc.autoTable({ head: [pdf_header], body: pdf_data });
                    doc.save(`${_id.value}_.pdf`);
                } else if (this.props.fileType === "xls") {
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }

    }

    viewData = () => {
        let headerRaw = this.props.reportData && Object.keys(this.props.reportData);
        const header = this.props.reportData && headerRaw.map(item => item.toUpperCase().replace('_', ' '));
        console.log(header);
        let body = [], row = [];

        if (header) {
            console.log(this.props.reportData)
            for (let i = 0; i < this.props.reportData[headerRaw[0]].length; i++) {
                row = headerRaw.map(item => this.props.reportData[item][i]);
                body.push(row);
                row = [];
            }
        }
        console.log(body)
        return [header, body];
    }

    render() {
        let renderComponent = null;
        console.log(this.props.method);
        console.log(this.props.reportData);
        // console.log(t)
        let header, body;
        if (this.props.method === actionTypes.REPORT_DOWNLOAD) {
            this.downloadData();
        }
        else if (this.props.method === actionTypes.REPORT_VIEW) {
            [header, body] = this.viewData();
            if (body && header) {
                {
                    renderComponent = <div className="col-md-8">
                        <div
                            className="demo-card-wide neu_card mdl-card "
                            themeColor={this.props.dataColor} style={{ maxHeight: "500px" }}>
                            <Table rows={body} headers={header} />
                        </div>
                    </div>
                }
            }
        }
        if (this.props.loading) {
            {
                renderComponent = <div className="col-md-8">
                    <div
                        className="demo-card-wide neu_card mdl-card "
                        themeColor={this.props.dataColor} style={{ maxHeight: "500px" }}>
                        <Spinner theme="blue" />
                    </div>
                </div>
            }
        }

        return (
            <div className="content" style={{ margin: "30px" }}>
                <h4 className="pageTitle" color="light">Reports</h4>
                <div className="row">
                    <div className="col-md-4">
                        <div
                            className="demo-card-wide neu_card mdl-card"
                            themeColor={this.props.dataColor}>
                            <DataBackup />
                        </div>
                    </div>
                    {renderComponent}

                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.reports.loading,
        error: state.reports.error,
        reportData: state.reports.reportData,
        method: state.reports.method,
        fileType: state.reports.fileType
    }
}

export default connect(mapStateToProps, null)(Reports);